<template>
  <div class="net-start-component">
    <div class="box">
      <div class="title-box">
        <span class="hourglass-icon"></span>
        报名尚未开始
      </div>
      <div class="return-btn" @click="$router.go(-1)">返回上一步</div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.net-start-component {
  width: 100%;
  height: 722px;
  opacity: 1;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../../assets/images/apply-page-icon.png) no-repeat 100%/100%;
}

.box {
  width: 1356px;
  height: 716px;
  border: 3px solid #e01811;
  opacity: 1;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-box {
  width: 100%;
  font-size: 54px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 112px;
  color: #e01811;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  margin-top: 100px;
}

.hourglass-icon {
  display: inline-block;
  width: 120px;
  height: 120px;
  background: url(../../../assets/images/hourglass-icon.png) no-repeat 100% /
    100%;
  margin-right: 16px;
}

.return-btn {
  width: 224px;
  height: 64px;
  color: #ffffff;
  background: #e01811;
  opacity: 1;
  border-radius: 0px;
  text-align: center;
  line-height: 64px;
  cursor: pointer;
  margin-top: 148px;
}
</style>