<template>
  <div class="apply-view">
    <!-- 报名系统页面 -->
    <!-- 招聘详情页 -->
    <!-- <div class="navigation-bar">
      <div>首页</div>
      <div class="right-icon"></div>
      <div>招聘公告</div>
      <div class="right-icon"></div>
      <div class="">事业单位招聘公告</div>
      <div class="right-icon"></div>
      <div class="current-page-title">系统报名</div>
    </div> -->
    <component :is="isShow"></component>
  </div>
</template>

<script>
import NetStart from "./components/NetStart.vue";
export default {
  name: "applyPage",
  components: {
    NetStart,
  },
  data() {
    return {
      isShow: "NetStart",
    };
  },
};
</script>

<style scoped>
.navigation-bar {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  opacity: 1;
  display: flex;
  align-items: center;
  padding: 16px 0px;
}

.navigation-bar > div {
  margin-right: 12px;
}
.right-icon {
  width: 16px;
  height: 16px;
  background: url(../../assets/images/right-arrow.png) no-repeat 100%/100%;
}

.current-page-title {
  font-size: 16px;
  font-weight: 500;
  color: #e01811;
  opacity: 1;
}
</style>